import React from 'react';


function QuestionTitle(props) {
    const {popup}=props;
    const titleClassName = popup ? 'question-title-popup' : 'question-title';
   
    return (
           <div className={titleClassName}>
                <span style={{ color: '#969696' }}>{props?.label}</span>
                <span style={{ color: '#0A2458' }}>{props?.labelAdd}</span>
                <span style={{ color: '#969696' }}>{props?.labelAddMiddle}</span>
                <span style={{ color: '#0A2458' }}>{props?.labelAddLast}</span>
            </div>

    );
}

export default QuestionTitle