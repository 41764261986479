import { useEffect, useMemo, useState } from "react";
import Bad from "../../../assets/images/bad.svg";
import Medium from "../../../assets/images/medium.svg";
import Mild from "../../../assets/images/mild.svg";
import Good from "../../../assets/images/smile.svg";
import Worst from "../../../assets/images/worst.svg";
import "./MultiSelect.css";

import { useDispatch, useStore } from "react-redux";
import Awful from "../../../assets/images/aweful.svg";
import PopupComponent from "../popupComponent/PopupComponent";
function MultiSelect(props) {
  useEffect(() => {
    const icons = [
      "medium.svg",
      "mild.svg",
      "bad.svg",
      "smile.svg",
      "worst.svg",
    ];
    icons.forEach((name) => {
      import(`../../../assets/images/${name}`)
        .then((image) => {
          const img = new Image();
          img.src = image.default; // Use the imported module's default value (the actual file path)
        })
        .catch((error) => {
          console.error(`Error preloading image ${name}:`, error);
        });
    });
  }, []);

  const { questionID, checkboxData, followupQuestions } = props;
  const response = useStore().getState().responses[questionID];

  const dispatch = useDispatch();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    response?.map(({ option }) => option) || []
  );

  const [popoverQuestion, setPopoverQuestion] = useState();
  const [selectCofirmBox, setSelectCofirmBox] = useState({
    confirm: false,
    selectnone:
      Array.isArray(response) &&
      response.length > 0 &&
      response[0]?.option === "None",
  });
  const [selectedIcon, setSelectedIcon] = useState(response ? true : false);
  const [responses, setResponses] = useState(response);
  const store = useStore();
  const updateResponses = () => {
    const state = store.getState();
    setResponses(state?.responses[questionID] ?? []);
    const newCheckedBoxes = responses?.map((res) => res.option);
    setSelectedCheckboxes(newCheckedBoxes);
  };
  store.subscribe(updateResponses);

  const iconMap = useMemo(() => {
    return new Map([
      ["Good", Good],
      ["Bad", Bad],
      ["Worst", Worst],
      ["Severe", Worst],
      ["Mild", Mild],
      ["Medium", Medium],
      ["Awful", Awful],
      ["Moderate", Medium],
    ]);
  }, []);
  const onChange = (name) => {
    if (name === "None" && selectedCheckboxes.length === 0) {
      dispatch({
        type: "ADD_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: "checkbox",
          response: {
            option: name,
            followupResponse: "",
          },
        },
      });
      setSelectedCheckboxes(["None"]);
      setTimeout(() => {
        setSelectCofirmBox({ confirm: false, selectnone: true });
      }, 200);
    } else if (
      name === "None" &&
      selectedCheckboxes?.find((i) => i === "None") == null
    ) {
      setTimeout(() => {
        setSelectCofirmBox({ ...selectCofirmBox, confirm: true });
      }, 200);
    } else if (
      name === "None" &&
      selectedCheckboxes?.find((i) => i === "None") != null
    ) {
      setTimeout(() => {
        setSelectCofirmBox({ confirm: false, selectnone: false });
      }, 200);
      dispatch({
        type: "REMOVE_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: "checkbox",
          response: {
            option: name,
          },
        },
      });
      setSelectedCheckboxes([]);
    } else {
      const index = selectedCheckboxes.indexOf(name);
      if (index === -1) {
        setPopoverQuestion(
          followupQuestions?.find((q) => q.optionName === name)
        );
        setSelectedCheckboxes([...selectedCheckboxes, name]);
        setSelectedIcon(true);
      } else {
        dispatch({
          type: "REMOVE_RESPONSE",
          payload: {
            questionID: questionID,
            responseType: "checkbox",
            response: {
              option: name,
            },
          },
        });
      }
    }
  };

  const handleUpdate = (name, e) => {
    e.preventDefault();
    e.stopPropagation();
    setPopoverQuestion(followupQuestions?.find((q) => q.optionName === name));
  };

  const handleClose = (e) => {
    setTimeout(() => {
      setPopoverQuestion(null);
    }, 200);

    if (selectCofirmBox.confirm && !e?.selectNone) {
      setTimeout(() => {
        setSelectCofirmBox({ confirm: false, selectnone: false });
      }, 200);
    } else if (selectCofirmBox.confirm && e?.selectNone === true) {
      setSelectedIcon(false);
      setTimeout(() => {
        setSelectCofirmBox({ confirm: false, selectnone: true });
      }, 200);
      dispatch({
        type: "CLEAR_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: "checkbox",
          response: {},
        },
      });
      setSelectedCheckboxes(["None"]);
    } else {
      if (selectedCheckboxes.includes("None")) {
        selectedCheckboxes.filter((box) => box !== "None");
        setSelectCofirmBox({ confirm: false, selectnone: false });
        dispatch({
          type: "REMOVE_NONE",
          payload: {
            questionID: questionID,
            responseType: "checkbox",
          },
        });
      }
      updateResponses();
    }
  };

  const handleRevert = (val, name) => {
    setTimeout(() => {
      setPopoverQuestion(null);
    }, 200);
    if (val == null) {
      dispatch({
        type: "REMOVE_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: "checkbox",
          response: {
            option: name,
          },
        },
      });
    } else {
      dispatch({
        type: "ADD_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: "checkbox",
          response: {
            option: name,
            followupResponse: val,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (response) {
      setSelectedCheckboxes(response.map((item) => item.option));
    } else setSelectedCheckboxes([]);
  }, [response]);

  return (
    <div className="checkbox-container">
      {checkboxData?.map((item) => {
        const selectedResponse = responses?.find((res) => res.option === item);

        const iconSrc =
          selectedResponse != null
            ? iconMap.get(selectedResponse.followupResponse)
            : null;

        const textResponse =
          iconSrc == null ? selectedResponse?.followupResponse : null;

        let isChecked = selectedCheckboxes?.includes(item);

        return (
          <div
            className={`checkitem-container  ${isChecked ? "checked" : ""}`}
            key={item}
            onClick={() => onChange(item)}
          >
            <div className="form-check checkbox-item">
              <input
                className="form-check-input"
                type="checkbox"
                value={item}
                id="flexCheckDefault"
                checked={isChecked}
                onChange={() => {}}
              />
              {item.includes("(") !== true && (
                <label className="form-check-label btn-title">{item}</label>
              )}
              {item.includes("(") === true && (
                <label className="form-check-label btn-title">
                  {item.replace(/ *\([^)]*\) */g, "")}{" "}
                  <span>{"(" + item.match(/\((.*)\)/).pop() + ")"}</span>
                </label>
              )}
            </div>
            {selectedIcon && iconSrc != null && (
              <span
                onClick={(e) => handleUpdate(item, e)}
                className="select-icon"
              >
                {" "}
                <img src={iconSrc} alt="rating" />
              </span>
            )}
            {selectedIcon && textResponse?.length ? (
              <span
                onClick={(e) => handleUpdate(item, e)}
                className="selected-text input-unit"
              >
                {textResponse + "°F"}
              </span>
            ) : null}
          </div>
        );
      })}
      {popoverQuestion != null && (
        <PopupComponent
          questionID={questionID}
          question={popoverQuestion}
          confirmBox={false}
          handleClose={handleClose}
          handleRevert={handleRevert}
          handleClick={handleClose}
        />
      )}
      {selectCofirmBox?.confirm && (
        <PopupComponent
          cofirmBox={setSelectCofirmBox}
          confirmBox={true}
          handleClose={handleClose}
        ></PopupComponent>
      )}
    </div>
  );
}

export default MultiSelect;
