import React from "react";
import styled, { keyframes } from "styled-components/macro";

const Loader = ({ size, padding, position = "relative", style }) => {
  return (
    <LoaderWrap position={position}>
      <Container size={size} padding={padding} style={style}>
        <Progress></Progress>
      </Container>
    </LoaderWrap>
  );
};

export default Loader;

const LoaderWrap = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => position || "relative"};
  min-height: 3rem;
  background-color: #fff;
`;

const Container = styled.div`
  height: ${({ size }) => size || "3.75rem"};
  width: ${({ size }) => size || "3.75rem"};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: ${({ padding }) => padding || "1rem"};
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Progress = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 4px solid #e6effe;
  animation: ${spin} 0.75s linear infinite;
  border-bottom-color:#005FF2 ;

`;
