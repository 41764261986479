import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import "./InputComponent.css";

function InputComponent(props) {
  const store = useStore();

  const responses = store.getState().responses[props.questionID];
  const [value, setValue] = useState(() => {
    if (Array.isArray(responses)) {
      const result = responses.find((res) => res.option === props.optionName);
      return result?.followupResponse || "";
    }
    return responses || "";
  });
  const [isValidData, setIsValidData] = useState({
    isValid: true,
    errorMsg: "",
  });

  const inputRef = useRef(null);
  useEffect(() => {
    if (props.modalVisible && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [props, props.modalVisible]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    let valid = inputValidation(e);

    setIsValidData(valid);
    setValue(e.target.value);

    const isBtnDisabled = !valid.isValid;

    props?.onInputChange && props.onInputChange({ isBtnDisabled });

    dispatch({
      type: "ADD_RESPONSE",
      payload: {
        questionID: props.questionID,
        responseType: props.optionName == null ? "number" : "checkbox",
        response:
          props.optionName == null
            ? e.target.value
            : { option: props.optionName, followupResponse: e.target.value },
        isValidRes: valid.isValid,
      },
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      let valid = inputValidation(e);
      setIsValidData(valid);
      if (valid?.isValid && (props?.popup || e.key === "Enter")) {
        props?.handleClick({ valid: true });
      }
    }
  };

  const inputValidation = (e) => {
    let inputValue = e.target?.value.trim(); // Trim any leading/trailing spaces
    if (inputValue === "") {
      return { isValid: false, errorMsg: "" };
    }

    let inputValInt = parseFloat(e.target?.value);
    let decimalLength = e.target?.value
      ? e.target?.value.split(".")[1]?.length
      : 0;

    if (inputValue.length === 1) {
      return { isValid: false, errorMsg: "", oneDigit: true }; //For input values onDigit value is not considered as Error
    } else if (
      isNaN(inputValInt) ||
      inputValInt > parseFloat(props.max) ||
      inputValInt < parseFloat(props.min)
    ) {
      let errorMsg;
      if (props.questionID === 5) {
        errorMsg = `Enter valid weight`;
      } else {
        errorMsg = `Range ${parseInt(props.min)} - ${parseInt(props.max)} ${
          props.unit
        }.`;
      }
      return {
        isValid: false,
        errorMsg: errorMsg,
      };
    } else if (decimalLength > parseInt(props.decimalLen)) {
      return {
        isValid: false,
        errorMsg: `Entered value can have ${parseInt(
          props.decimalLen
        )} decimal point(s).`,
      };
    } else {
      return { isValid: true };
    }
  };

  return (
    <div
      className={
        props.optionName === "Fever"
          ? "input-main-container"
          : "input-main-containerWeight"
      }
    >
      {props?.title && <span className="card-title">{props?.title}</span>}
      <div className="input-container">
        <input
          ref={inputRef}
          name={props.id}
          type={props?.type}
          inputMode={props?.inputMode}
          pattern={props?.pattern}
          placeholder={props?.placeholder}
          value={value}
          onChange={(e) => {
            return handleChange(e);
          }}
          onKeyDown={handleKeyPress}
          className={`inputBox question-title ${
            !isValidData.isValid && !isValidData.oneDigit && "error-input"
          } ${props?.optionName === "Fever" ? "inputBox1" : "inputBox"}`}
        />
        {props?.unit && (
          <span className="unitItem input-unit">{props?.unit}</span>
        )}
      </div>
      <div className="error-container">
        <span className="hidden">
          <span className="error-text">{isValidData.errorMsg || " "}</span>
        </span>
      </div>
    </div>
  );
}

export default InputComponent;
