import React from "react";
import RadioButton from "../radiobutton/RadioButton";
import QuestionTitle from "./QuestionTitle";

function RadioOptionQuestion(props) {
  return (
    <div className="main-question-container">
      {props?.title && <QuestionTitle label={props?.title} labelAdd={props.titleAdd} labelAddMiddle={props.titleAddMiddle} labelAddLast={props.titleAddLast} />}
      {props?.optionList && (
        <div>
          <RadioButton
            questionID={props?.questionID}
            radioData={props?.optionList}
            className="checkbox-container"
          />
        </div>
      )}
    </div>
  );
}

export default RadioOptionQuestion;
