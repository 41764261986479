import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./RadioButton.css";
function RadioButton(props) {
  const responses = useSelector((state) => state.responses);

  const [selectedOption, setSelectedOption] = useState(
    responses[props.questionID]
  );
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch({
      type: "ADD_RESPONSE",
      payload: {
        questionID: props.questionID,
        responseType: "radio",
        response: value,
      },
    });
  };

  useEffect(() => {
    setSelectedOption(responses[props.questionID]);
  }, [responses, props.questionID]);

  return (
    <div className="radio-container">
      {props?.radioData?.map((item,i) => {
        return (
          <div
            className={`form-check radio-item ${
              selectedOption === item
                ? "radio-item-color-selected"
                : "radio-item-color-notselected"
            }`}
            key={props.questionID + item}
            onClick={() => handleChange(item)}
          >
            <input
              className="form-check-input"
              type="radio"
              value={item}
              id={props.questionID + item}
              name={props.questionID}
              onChange={(e) => handleChange(e.currentTarget.value)}
              checked={selectedOption === item}
            />
            <label className="form-check-label btn-title" htmlFor={item}>
              {item}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default RadioButton;
