import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { Carousel, Row } from "react-bootstrap";
import { useDispatch, useStore } from "react-redux";
import "./GlobalStyles.css";
import screenRotate from "./assets/images/Screen_Rotate.png";
import arrowLeft from "./assets/images/arrow-left.svg";
import arrowRight from "./assets/images/arrow-right.svg";
import { questionData } from "./data/index";
import HomeLayout from "./pages/HomeLayout";
import LinkExpiredPage from "./pages/LinkExpiredPage";
import ThankYouPage from "./pages/ThankYouPage";
import Loader from "./shared/components/Loader";
import ProgressBarComponent from "./shared/components/ProgressBarComponent/ProgressBarComponent";
import FooterButton from "./shared/components/footerButton/FooterButton";
import QuestionContainer from "./shared/components/questionContainer/QuestionContainer";

import { decodeJWT, formatDate } from "./utils";
import NetworkErrorPage from "./pages/NetworkErrorPage";
function App() {
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [isFooterButtonDisabled, setIsFooterButtonDisabled] = useState(true);
  const [responseSubmitted, setResponseSubmitted] = useState(false);
  const store = useStore();
  const [totalQuestions, setTotalQuestions] = useState();
  const [questions, setQuestions] = useState();
  const questWrapper = useRef();
  const questionContainer = useRef(null);
  const [isPreviousClick, setIsPrevious] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitResponse, setSubmitResponse] = useState(false);
  const [fadeout, setFadeout] = useState(false);
  const [NetworkError, setNetworkError] = useState(false);

  const [isTokenValid, setIsTokenValid] = useState(true);
  const [expiryDate, setExpiryDate] = useState(formatDate(new Date()));
  const [nextExpiryDate, setNextExpiryDate] = useState(
    formatDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
  );
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("code")?.trim();
  const { frequencyType, isLastSurvey, isEnded } = decodeJWT(token);
  // frequency type will be decoded from token
  const dispatch = useDispatch();
  useEffect(() => {
    if(isEnded){
      setSubmit(true);
      return;
    }
    if (!frequencyType) {
      setIsTokenValid(false);
    } else {
      let questData = questionData[frequencyType];
      setTotalQuestions(questData?.question_count);
      setQuestions(questData.questions_list);
    }
  }, [frequencyType]);

  useEffect(() => {
    checkValidResponse();
    setProgress(((index + 1) / totalQuestions) * 100);
    setTimeout(() => {
      window.scrollTo({
        top: "-64",
        behavior: "smooth",
      });
    }, 0);
  }, [index, totalQuestions]);

  const followupQuestionsMap = {
    "Shortness of breath": "breathlessness",
    "Cough / Wheezing": "cough",
    "Swelling of legs": "edema",
    "Light-headed": "light_headed",
    "Feeling tired": "fatigue",
    Fever: "fever",
    Vomiting: "vomiting",
    Diarrhea: "diarrhea",
    Confusion: "confusion",
    Nausea: "nausea",
    Breathlessness: "breathlessness",
  };

  const questionIDMap = {
    fatigue: 3.1,
    breathlessness: 3.2,
    light_headed: 3.3,
    nausea: 3.4,
    confusion: 3.5,
    cough: 4.1,
    edema: 4.2,
    fever: 4.3,
    vomiting: 4.4,
    diarrhea: 4.5,
  };

  const getExpirydate = () => {
    const { date } = decodeJWT(token || "");
    const expiryDate = formatDate(date || null);
    return expiryDate;
  };

  const submitFeedback = async (feedback, finalSubmission, first) => {
    const payload = { ...feedback };
    if (!first) payload.laq_id = index;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${baseUrl}/patient/feedback-response`,
        payload,
        {
          params: {
            finalSubmission,
          },
          headers: {
            "session-token": `session-token ${token}`,
          },
        }
      );
      const data = response.data?.data;
      if (data && first) prepareData(data);
      if (response && finalSubmission) {
        questWrapper.current.classList.add("pt-page-moveToLeft")
        setSubmit(true);
        setSubmitResponse(true);
      }
      setExpiryDate(getExpirydate());
      setLoading(false);
      setNetworkError(false);
    } catch (error) {
      setLoading(false);
      const status = error?.response?.status;
      if (error?.message === "Network Error" || status === 500) {
        setNetworkError(true);
      } else {
        setNetworkError(false);
      }
      if (error?.response?.data?.message === "Response already Submitted") {
        setSubmit(true);
        setResponseSubmitted(true);
        setExpiryDate(getExpirydate());
      } else {
        setIsTokenValid(false);
        const { date } = decodeJWT(token || "");
        setExpiryDate(getExpirydate());
        if (date) {
          let inputDate = new Date(date);
          inputDate?.setDate(inputDate?.getDate() + 1);
          const nextDayExpiry = formatDate(
            inputDate?.toISOString().split("T")[0] || null
          );
          setNextExpiryDate(nextDayExpiry);
        }
      }
    }
  };
  const prepareData = ({ laq_id, ...data }) => {
    let questData = questionData[frequencyType];
    const questions = questData?.questions_list || [];
    const response = {};
    if (frequencyType === "biDiurnal") {
      response[3] = [];
      response[4] = [];
    }
    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value !== null) {
        const id = questions?.find((ques) => ques?.key === key)?.id;
        if (id) {
          response[id] = value;
        } else {
          const optionName = Object.keys(followupQuestionsMap).find(
            (ques) => followupQuestionsMap[ques] === key
          );
          const id = questionIDMap[key];
          if (optionName && id) {
            const questionID = parseInt(id);
            response[questionID] = [
              ...response[questionID],
              {
                option: optionName,
                followupResponse: value,
              },
            ];
          }
        }
      }
    });

    if (response[4]?.length === 0 && (data.weight || laq_id >= 3))
      response[4].push({ option: "None", followupResponse: "" });
    if (response[3]?.length === 0 && (response[4]?.length > 0 || laq_id >= 2))
      response[3].push({ option: "None", followupResponse: "" });

    dispatch({
      type: "BULK_UPDATE",
      payload: {
        response,
      },
    });
    if (typeof laq_id === "number") setIndex(laq_id + 1);
  };

  useEffect(() => {
    submitFeedback({}, false, true);
  }, []);
  const handleSubmit = (finalSubmission) => {
    if(!finalSubmission) questWrapper.current.classList.add("pt-page-moveToLeft");
    const { responses } = store.getState();

    let feedback = {};
    Object.keys(responses).forEach((key) => {
      const response = responses[key];
      const question = questions[key - 1];
      if (question.hasFollowupQuestions) {
        response?.forEach(({ option, followupResponse }) => {
          const key = followupQuestionsMap[option];
          if (key) feedback[key] = followupResponse;
        });
      } else {
        const { key } = question;
        if (key) feedback[key] = response;
      }
    });
    frequencyType === "biDiurnal" &&
      Object.values(followupQuestionsMap).forEach((key) => {
        if (!feedback[key]) {
          feedback[key] = null;
        }
      });
    submitFeedback(feedback, finalSubmission);
  };
  const checkValidResponse = () => {
    const state = store.getState();
    const responses = state.responses;
    const questionID = questions ? questions[index]?.id : 0;
    setIsFooterButtonDisabled(
      responses[questionID] == null ||
        responses[questionID] === "" ||
        responses[questionID]?.length === 0 ||
        !state.isValidRes
    );
  };

  const previousButtonClick = (index) => {
    const questionID = questions ? questions[index].id : 0;
    const type = questions ? questions[index].type : "";
    const state = store.getState();
    if (type === "number" && !state.isValidRes) {
      dispatch({
        type: "REMOVE_RESPONSE",
        payload: {
          questionID: questionID,
          responseType: type || "number",
        },
      });
      dispatch({
        type: "UPDATE_ISVALID_RESPONSE",
        payload: {
          isValidRes: true,
        },
      });
    }
    if (index > 0 && index < totalQuestions) {
      setIndex((index) => index - 1);
    }
    setTimeout(() => {
      setIsPrevious(true);
    }, 200);
  };

  store.subscribe(checkValidResponse);

  const handleClose = () => {
    setFadeout(true);
    setTimeout(() => {
      window.close();
      window.history.go(-1);
    }, 2000);
  };

  return (
    <HomeLayout>
      {loading ? (
        <Loader size="5rem" />
      ) : (
        <>
          {!submit && isTokenValid ? (
            <div className="main-container" ref={questWrapper}>
              {index > 0 ? (
                <Row className="progressbar-containerButton">
                  <div className="progress-icon-container">
                    <img
                      onClick={() => previousButtonClick(index)}
                      src={arrowLeft}
                      alt="Arrow Icon"
                    />

                    {/* <div className="progress-bar-wrapper"> */}
                    <ProgressBarComponent
                      bgcolor="#F56F50"
                      value={progress}
                      index={index}
                    />
                    {/* </div> */}
                  </div>
                </Row>
              ) : (
                <Row className="progressbar-container">
                  <ProgressBarComponent bgcolor="#F56F50" value={progress} />
                </Row>
              )}

              <Row className="questionmain-container">
                <Carousel
                  controls={false}
                  indicators={false}
                  activeIndex={index}
                  fade
                >
                  {questions?.map((item, index) => (
                    <Carousel.Item
                      key={item.key || index}
                      className={
                        isPreviousClick ? "carousel-previous" : "carousel-next"
                      }
                    >
                      {index < totalQuestions && (
                        <QuestionContainer
                          forwardRef={questionContainer}
                          question={item}
                          handleClick={(e) => {
                            handleSubmit(index === totalQuestions - 1);
                            index !== totalQuestions - 1 &&
                              setIndex((index) => index + 1);
                          }}
                        />
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
              <Row
                className="footer-main-container"
                data-test="footer-main-container"
              >
                <div className="footer-container">
                  <FooterButton
                    btnWrapperClass={`${index > 0 && "next-btn"}`}
                    isDisabled={isFooterButtonDisabled}
                    label={index === totalQuestions - 1 ? "Submit" : "Next"}
                    icon={index === totalQuestions - 1 ? null : arrowRight}
                    variant={index === totalQuestions - 1 ? "submit" : null}
                    btnClick={() => {
                      handleSubmit(index === totalQuestions - 1);
                      index !== totalQuestions - 1 &&
                        setIndex((index) => index + 1);
                      setTimeout(() => {
                        setIsPrevious(false);
                      }, 200);
                    }}
                  />
                </div>
              </Row>
            </div>
          ) : (
            <div
              className={`${
                responseSubmitted ? "" : "pt-page-moveFromRightFade"
              }`}
            >
              {submit ? (
                <ThankYouPage
                  submitted={responseSubmitted}
                  submitResponseFirst={submitResponse}
                  closePage={() => handleClose()}
                  nextDayForm={expiryDate}
                  fadeout={fadeout}
                  isLastSurvey={isLastSurvey}
                  token={token}
                />
              ) : (
                <div className="invalid-token-message">
                  {NetworkError ? (
                    <NetworkErrorPage closePage={() => handleClose()} />
                  ) : (
                    <LinkExpiredPage
                      frequencyType={frequencyType}
                      expiryDate={expiryDate}
                      nextExpiryDate={nextExpiryDate}
                      closePage={() => handleClose()}
                      fadeout={fadeout}
                      isLastSurvey={isLastSurvey}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div className="question-title rotate-screen">
        <img src={screenRotate} alt="Screen Rotate" />
        <p>Please open the app in Mobile device or Rotate your Device.</p>
      </div>
    </HomeLayout>
  );
}

export default App;
