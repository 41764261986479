import React from 'react';
import CardComponent from '../cardComponent/CardComponent';
import QuestionTitle from './QuestionTitle';

function BooleanQuestion(props) {
  return (
      <div className='main-question-container question-absolute-container'>
        {props?.title && <QuestionTitle label={props?.title} labelAddMiddle={props?.titleAddMiddle} popup={props.popup} labelAdd={props?.titleAdd}/>}
        {props?.BooleanAnswer && ( 
        <div className='answer-section'>
          <CardComponent questionID={props?.questionID} cardListData={props?.BooleanAnswer} booleanContainer={true}/>
        </div>
        )}
      </div>
  ) 
}

export default BooleanQuestion;