import React, { useMemo } from "react";
import BooleanQuestion from "../questionComponents/BooleanQuestion";
import MultiSelectQuestion from "../questionComponents/MultiSelectQuestion";
import NumberQuestion from "../questionComponents/NumberQuestion";
import RadioOptionQuestion from "../questionComponents/RadioOptionQuestion";
import RatingQuestion from "../questionComponents/RatingQuestion";
function QuestionContainer(props) {
  const { question } = props;

  const questionComponent = useMemo(() => {
    switch (question?.type) {
      case "rating":
        return (
          <RatingQuestion
            questionID={question?.id}
            answerList={question?.options}
            title={question?.label}
            titleAdd={question?.labelAdd}
          />
        );
      case "checkbox":
        return (
          <MultiSelectQuestion
            question={question}
            placeholderIcon={true}
            title={question?.label}
            titleAdd={question?.labelAdd}
            answerList={question?.options}
          />
        );
      case "number":
        return (
          <NumberQuestion
            questionID={question?.id}
            title={question?.label}
            unit={question?.unit}
            inputTitle={question?.subLabel}
            placeholder={question?.placeholder}
            handleClick={props?.handleClick}
            type={question?.type}
            pattern={question?.pattern}
            inputMode={question?.inputMode}
            min={question?.min}
            max={question?.max}
            decimalLen={question?.decimalLen}
            titleAdd={question?.labelAdd}
          />
        );
      case "boolean":
        return (
          <BooleanQuestion
            questionID={question.id}
            BooleanAnswer={["Yes", "No"]}
            title={question?.label}
            titleAdd={question?.labelAdd}
            titleAddMiddle={question?.labelAddMiddle}
          />
        );
      case "radio":
        return (
          <RadioOptionQuestion
            questionID={question.id}
            placeholderIcon={true}
            title={question?.label}
            titleAdd={question?.labelAdd}
            titleAddMiddle={question?.labelAddMiddle}
            titleAddLast={question?.labelAddLast}
            optionList={question?.options}
          />
        );
      default:
    }
  }, [question]);

  return questionComponent;
}

export default QuestionContainer;
