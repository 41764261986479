import axios from "axios";
import { useEffect, useState } from "react";
import { ReactComponent as LoaderButtonIcon } from "../assets/icons/loaderButton.svg";
import { ReactComponent as Star } from "../assets/icons/star.svg";
import FooterButton from "../shared/components/footerButton/FooterButton";

export default function FeedbackPage(props) {
  const { closePage, token } = props;
  const [showInput, setShowInput] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(true);
  const [feedbackError, setFeedbackError] = useState(false);

  const [initialState, setInitialState] = useState({
    overallExperience: null,
    improvementFeedback: "",
  });

  const experienceFeedbackSubmit = async (payload = {}, isSubmitClick = false) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${baseUrl}/patient/experience-feedback`,
        payload,
        {
          headers: {
            "session-token": `session-token ${token}`,
          },
        }
      );
      setFeedbackLoading(false);
      isSubmitClick && closePage();
      return response?.data?.data;
    } catch (error) {
      const status = error?.response?.status;
      if (status === 500) {
        setFeedbackLoading(false);
        setFeedbackError(isSubmitClick);  
      }
      console.log("error", error);
      return {};
    }
  };

  const response = ["Poor", "Fair", "Good", "Very Good !", "Excellent !"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await experienceFeedbackSubmit();
        if (data) {
          setInitialState(data);
          setShowInput(data?.overallExperience && data.overallExperience < 4);
        }
        setFeedbackLoading(false);
      } catch (err) {
        console.log("error from feedbackPage", err);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (idx) => {
    setFeedbackError(false);
    setInitialState((prevState) => ({
      ...prevState,
      overallExperience: idx + 1,
    }));
    if (idx < 3) {
      setShowInput(true);
    } else {
      setShowInput(false);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 0);
    }
  };

  const handleChange = (e) => {
    setInitialState((prevState) => ({
      ...prevState,
      improvementFeedback: e.target.value,
    }));
  };

  return (
    <>
      <div className="feedback-container">
        <div className="response-container">
          <span className="heading">How was the overall experience?</span>
          <div className="response-div">
            <div className="star-div">
              {response.map((res, idx) => {
                return (
                  <div
                    key={`star-${res}`}
                    className={
                      initialState.overallExperience > idx
                        ? "filledStar"
                        : "emptyStar"
                    }
                  >
                    <Star
                      onClick={() => handleClick(idx)}
                      alt={`star${idx}`}
                      key={`star-${res}`}
                    />
                  </div>
                );
              })}
            </div>
            <span className="response-text">
              {initialState.overallExperience > 0
                ? response[initialState.overallExperience - 1]
                : ""}
            </span>
          </div>
        </div>
      </div>
      {showInput && (
        <div className="text-area">
          <span>How could we do better?</span>
          <textarea
            placeholder="Your feedback"
            value={initialState.improvementFeedback || ""}
            onChange={handleChange}
          ></textarea>
        </div>
      )}
      <div className="error-message">
        <span className={!feedbackError ? "hidden-element" : ""}>
          There was an error. Please try submitting again
        </span>
        {initialState.overallExperience && <FooterButton
          label={
            <>
              {feedbackLoading ? (
                <LoaderButtonIcon style={{ height: 25, width: 25 }} />
              ) : "Submit Feedback"}
            </>
          }
          btnClass={
            !initialState.overallExperience ? "experience-secondary-btn" : ""
          }
          finalSubmit={"finalSubmit"}
          btnClick={async () => {
            setFeedbackLoading(true);
            await experienceFeedbackSubmit(initialState, true)
          }}
          isDisabled={
            feedbackLoading
              ? "disable"
              : ""
          }
          variant={"submit"}
        />}
      </div>
    </>
  );
}
