import { jwtDecode } from "jwt-decode";

export const decodeJWT = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return {};
  }
};

export const formatDate = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const addToDays = (inputDate, days) => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + days);
  return formatDate(date);
};
