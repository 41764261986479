import React from 'react'
import InputComponent from '../inputComponent/InputComponent';
import QuestionTitle from './QuestionTitle';

function NumberQuestion(props) {

  
    return (
        <div className='main-question-container' >
            {props?.title && <QuestionTitle label={props?.title}   popup={props?.popup} labelAdd={props?.titleAdd}/>}
            <InputComponent
               setDisableBtn={props?.setDisableBtn}
                optionName={props?.optionName}
                unit={props?.unit}
                type={props?.type}
                pattern={props?.pattern}
                inputMode={props?.inputMode}
                title={props?.inputTitle} 
                placeholder={props?.placeholder} 
                questionID={props?.questionID} 
                handleClick={props?.handleClick} 
                popup={props.popup} 
                modalVisible={props.modalVisible}
                min={props.min} 
                max={props.max}
                decimalLen={props?.decimalLen}
                onInputChange={props?.onInputChange}
            
            />
        </div>
    );
}

export default NumberQuestion;