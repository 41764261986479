import React from "react";
import ThankYou from "../assets/images/Thanks-you.svg";
import FooterButton from "../shared/components/footerButton/FooterButton";
import { addToDays } from "../utils";
import FeedBackPage from './FeedBackPage';

function ThankYouPage(props) {
  const { submitResponseFirst, nextDayForm, isLastSurvey, token } = props;
  const nextDayFormDate = addToDays(nextDayForm, 2);
  const nextDayMessage = nextDayForm
    ? ` Your next survey questionnaire will be sent at 8:00am EST, ${nextDayFormDate}`
    : "";

    let thankYouText;
    if (submitResponseFirst || isLastSurvey) {
        thankYouText = `for participating in the heart research study, 2024-25.${!isLastSurvey ? nextDayMessage : ''}`;
    } else {
        thankYouText = `It seems you have already provided feedback on your health status in this current session.${nextDayMessage}`;
    }    

  return (
    <div className={`${isLastSurvey ? 'feedback-content-div' : 'close-page-container'} ${props.fadeout ? 'fade-out' : ''}`}>
      <div className="close-page-container-ThanksYou">
        <div className="thank-you-container">
          <img src={ThankYou} alt="no img"></img>
        </div>
        <div className="Thanks-container">
          <div className="Thanks-container-heading">Thank You!</div>
          <div className="Thanks-container-text" data-test="Thanks-container">
            {thankYouText}
          </div>
          {/* <a href="tel:+1 716-298-2734" className="Thanks-container-call">
          Please call{" "}
          <span className="callDataColor">Mount saint Mary’s Heart Center</span> at{" "}
          <span className="callDataColor">+1 (716) 298-2734</span> if you have any
          questions or concerns.
        </a> */}
        </div>
      </div>
      {isLastSurvey && <FeedBackPage token={token} closePage={props?.closePage} />}
      <div className="close-btn-container" data-test="close-btn">
        {" "}
        {!isLastSurvey && <FooterButton
          label="Close"
          btnClass="closeBtnThanks"
          labeldata="thanksYou"
          btnClick={() => {
            return props?.closePage();
          }}
        />}
      </div>
    </div>
  );
}

export default ThankYouPage;
