import React, { useCallback } from 'react';
import MultiSelect from '../checkbox/MultiSelect';
import QuestionTitle from './QuestionTitle';

function MultiSelectQuestion(props) {

  const { question } = props;
  const checkFunction = useCallback((e) => { }, []);
      
  return (
    <div className='main-question-container'>
        {question?.label && <QuestionTitle label={question?.label}  labelAddMiddle={question?.labelAddMiddle} labelAdd={question?.labelAdd}/>}
        {question?.options && ( 
          <div className='answer-multiselect'>
            <MultiSelect
              questionID={question?.id}
              checkboxData={question?.options}
              followupQuestions={question?.followupQuestions}
              checkedData={checkFunction}
              className="checkbox-container"
              placeholderIcon={true}
            />
          </div>
        )}
      </div>
  ) 
}

export default MultiSelectQuestion;