import React from "react";
import "./FooterButton.css";

function FooterButton(props) {
  const labeldata = props.labeldata;

  let labelClassName;

  if (props.variant === "submit") {
    labelClassName = "submitLabel";
  } else if (props.variant === "Cancel") {
    labelClassName = "goBackLabel";
  } else if (labeldata === "thanksYou") {
    labelClassName = "footerButtonLabel";
  } else {
    labelClassName = "footerbuttonlable1";
  }

  let widthStyle;
  if (props.finalSubmit) {
      widthStyle = "21.5rem";
  } else if (props?.btnClass === "closeBtnError") {
      widthStyle = "18rem";
  } else {
      widthStyle = "";
  }

  return (
    <div
      className={
        props.fever === "fever"
          ? "footerBtn-fever"
          : `footer-btn ${props?.btnWrapperClass}`
      }
    >
      <button
        type="button"
        onClick={props?.btnClick}
        style={{ width: widthStyle}}
        className={`footerButton ${
          props?.label === "Cancel" ? "btn-title-cancel" : "btn-title"
        } ${props?.variant === "submit" ? "submitButton" : ""} ${
          props?.btnClass || ""
        } `}
        disabled={props?.isDisabled}
      >
        <span className={labelClassName}>{props?.label}</span>
        {props?.icon && (
          <img width="20" height="15" src={props?.icon} alt="next" />
        )}
      </button>
    </div>
  );
}

export default FooterButton;
