import React from "react";
import expiredIcon from "../assets/images/icon_expired.svg";
import FooterButton from "../shared/components/footerButton/FooterButton";

const LinkExpiredPage = (props) => {
  const {
    expiryDate,
    closePage,
    fadeout,
    nextExpiryDate,
    frequencyType,
    isLastSurvey,
  } = props;

  let date = new Date(expiryDate).getFullYear();
  const isAfter2022 = date > 2022;
  const time = frequencyType == "biDiurnal" ? "02:00 am" : "08:00 pm";
  return (
    <div
      className={`close-page-container link-Thanks ${fadeout && "fade-out"}`}
    >
      <img src={expiredIcon} alt="expiredIcon" className="expiredIcon" />
      <div className="expirePageData">
        <div className="headingExpireShow">Sorry, the link has expired.</div>
        <div className="TokenValidTiming-container">
          {isAfter2022 && (
            <div className="TokenValidTiming">
              The link has expired, it was valid from {expiryDate}, 8:00 am to{" "}
              {nextExpiryDate}, {time} EST.
            </div>
          )}
          {!isLastSurvey && (
            <div className="TokenValidTiming">
              Please wait for the next link
            </div>
          )}
        </div>

        <div className="close-btn-container">
          {" "}
          <FooterButton
            label="Close"
            labeldata="thanksYou"
            // btnClass="closeBtn"
            btnClick={() => {
              closePage();
            }}
            btnClass="closeBtnThanks"
          />
        </div>
      </div>
    </div>
  );
};

export default LinkExpiredPage;
