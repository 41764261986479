import { useEffect, useMemo, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import styled from "styled-components";
import {
  Checkmark,
  Crosslogo,
  MildIcon,
  ModerateIcon,
  SevereIcon,
} from "../../../assets/icons";
import Awful from "../../../assets/images/awful.svg";
import Bad from "../../../assets/images/bad.svg";
import CrossIcon from "../../../assets/images/cross.svg";
import Good from "../../../assets/images/good.svg";
import Moderate from "../../../assets/images/medium.svg";
import Mild from "../../../assets/images/mild.svg";
import TickIcon from "../../../assets/images/tick.svg";
import Severe from "../../../assets/images/worst.svg";
import "../popupComponent/PopupComponent.css";

function CardComponent(props) {
  // preload icons
  useEffect(() => {
    const icons = [
      "awful.svg",
      "cross.svg",
      "good.svg",
      "medium.svg",
      "mild.svg",
      "bad.svg",
      "tick.svg",
      "worst.svg",
    ];
    icons.forEach((name) => {
      import(`../../../assets/images/${name}`)
        .then((image) => {
          const img = new Image();
          img.src = image.default; // Use the imported module's default value (the actual file path)
        })
        .catch((error) => {
          console.error(`Error preloading image ${name}:`, error);
        });
    });
  }, []);

  const setDisableBtn = props.setDisableBtn;
  const store = useStore();
  const [selectedCard, setSelectedCard] = useState(() => {
    const responses = store.getState().responses[props.questionID] || null;
    if (Array.isArray(responses)) {
      const result = responses.find((res) => res.option === props.optionName);
      return result?.followupResponse;
    }
    return responses;
  });

  const dispatch = useDispatch();
  const cardClick = (e) => {
    if (props.popup) {
      setDisableBtn(false);
    }
    setSelectedCard(e);

    dispatch({
      type: "ADD_RESPONSE",
      payload: {
        questionID: props.questionID,
        responseType: props.optionName == null ? "rating" : "checkbox",
        response:
          props.optionName == null
            ? e
            : { option: props.optionName, followupResponse: e },
      },
    });
  };

  const iconMap = useMemo(() => {
    return new Map([
      ["Yes", TickIcon],
      ["No", CrossIcon],
      ["Good", Good],
      ["Bad", Bad],
      ["Worst", Severe],
      ["Severe", Severe],
      ["Mild", Mild],
      ["Medium", Moderate],
      ["Moderate", Moderate],
      ["Awful", Awful],
    ]);
  }, []);

  const updatedIconMap = {
    Mild: MildIcon,
    Moderate: ModerateIcon,
    Severe: SevereIcon,
    Yes: Checkmark,
    No: Crosslogo,
  };

  const borderColor = useMemo(() => {
    return new Map([
      ["Yes", "#00AC46"],
      ["No", "#C70000"],
      ["Good", "#00AC46"],
      ["Bad", "#FC7900"],
      ["Worst", "#C70000"],
      ["Mild", "#F7CB2F"],
      ["Medium", "#FC7900"],
      ["Moderate", "#FC7900"],
      ["Awful", "#C70000"],
      ["Severe", "#C70000"],
    ]);
  }, []);
  const colorBooleanText = useMemo(() => {
    return new Map([
      ["Yes", "#00AC46"],
      ["No", "#C70000"],
    ]);
  }, []);
  
  const getCardTitleColor = ({ color, popup, selected }) => {
    if (!selected) {
      return !popup ? "#012458" : color;
    } else {
      return popup ? "#ffff" : "#012458";
    }
  };

  return (
    <CardContainer
      className={`card-container ${
        props?.booleanContainer ? "boolean-container" : ""
      } ${props?.popup ? "popup-cards card-container-popup" : ""}`}
      style={{ flexDirection: props.booleanContainer ? "row" : "column" }}
    >
      {props?.cardListData?.map((item) => {
        const Icon = updatedIconMap[item];

        return (
          <Card
            key={item}
            className={`card-item ${
              props?.booleanContainer && "card-item-boolean"
            } ${selectedCard === item && "card-selected"} ${
              props?.popup && "popup-card-item"
            }`}
            selected={selectedCard === item}
            color={borderColor.get(item)}
            isBoolean={props.booleanContainer}
            colorBooleanText={colorBooleanText.get(item)}
            popup={props?.popup}
            onClick={() => cardClick(item)}
            getCardTitleColor={getCardTitleColor}
          >
            <div className="card-icon">
              {props?.popup || props.booleanContainer ? (
                <Icon />
              ) : (
                <img src={iconMap.get(item)} alt={item}></img>
              )}
            </div>
            <div
              className={`card-title ${
                props?.booleanContainer && "btn-title"
              } ${props.popup && "popup-card-title"}`}
            >
              {item === 'Bad' ? 'Bad   ' : item}
            </div>
          </Card>
        );
      })}
    </CardContainer>
  );
}

export default CardComponent;
const CardContainer = styled.div`
  &.card-container {
    display: flex;
    flex-direction: flexDirection;
    margin: 1rem 0;
    /* margin-top: 1.563rem; */
    justify-content: center;
    max-width: 30rem;
    height: 18.125rem;

    &.card-container-popup {
      height: auto; // Set the height to "auto" when the popup is displayed
    }
  }
  /* &.card-container.selected {
    gap: 0.3rem;  Adjusted gap value when a card is selected 
  } */
  &.boolean-container {
    margin: 1rem 1.3rem;
  }

  @media (max-width: 390px) {
    &.popup-cards {
      flex-direction: column !important;
      margin: 0px;
      height: 14rem;

      .card-item.card-selected {
        /* transform: scale(50%); */
      }
    }
  }
`;

const Card = styled.div`
  &.card-item {
    display: flex;
    justify-content: ${({ popup }) => (popup ? " " : "center")};
    align-items: center;
    min-height: 70px;
    background: #ffffff;
    margin-bottom: 0.625rem;
    width: 100%;
    border-radius: 20px;
    row-gap: 0.938rem;
    flex: 1;
    padding: ${({ popup }) => (popup ? " 0 5.219rem" : "")};

    &.card-selected {
      background-color: ${({ color }) => color+"CC" || "#FFFFFF"};

      .card-icon svg {
        width: ${({ isBoolean, selected }) =>
          isBoolean && selected ? "2.531rem" : "3.125rem"};
        height: ${({ isBoolean, selected }) =>
          isBoolean && selected ? "1.875rem" : "3.125rem"};
        path {
          fill: ${({ isBoolean }) => (isBoolean ? "none" : "#FFF")};
          stroke: ${({ color, isBoolean }) => (isBoolean ? "#FFF" : color)};
        }
      }
      .card-title {
        color: #ffffff;
        font-size: 28px;
        font-family: "Inter", Arial, sans-serif;
        font-weight: ${({ popup }) => (popup ? "500" : "700")};
        line-height: 25px;
        letter-spacing: -0.02em;
        text-align: center;
        /* font-size: ${({ isBoolean, selected }) =>
          isBoolean && selected ? "1.5rem" : " "};  */
        font-size: 21px;
        width: ${({ isBoolean, selected }) =>
          isBoolean && selected ? "3rem" : ""};
        height: ${({ isBoolean, selected }) =>
          isBoolean && selected ? "1.813rem" : ""};
      }
      .card-icon img {
        transition: width 0.3s ease-out;
      }

      transition: transform 0.3s ease-out;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .card-icon {
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    svg {
      width: ${({ isBoolean }) => (isBoolean ? "1.521rem" : "3.125rem")};
      height: ${({ isBoolean }) => (isBoolean ? "1.521rem" : "3.125rem")};
    }
  }

  .card-icon img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .card-title {
    text-align: center;
    white-space: pre-wrap;
    margin-left: 10px;
    margin-right: 15px;

    color: ${({ getCardTitleColor, color, popup, selected }) =>
      getCardTitleColor({ color, popup, selected })};
    font-size: 21px;
    font-weight: 500;
  }

  &.card-item-boolean {
    max-width: 9.188rem;
    height: 8.75rem;
    margin-right: 1.3rem;
    &:last-child {
      margin-right: 0;
    }

    .card-title {
      color: ${(props) =>
        props?.colorBooleanText ? props?.colorBooleanText : "#000"};
      margin-right: 0.5rem;
      font-weight: 700;
      margin-right: 1.1rem;
      font-family: Inter;
      font-size: 19.465px;
      font-style: normal;
      font-weight: 700;
      margin-right: 10px;
    }

    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    .card-icon {
      display: flex;
      justify-content: center;
      width: 2.531rem;
      height: 1.875rem;
      margin-right: 0px;

      img {
        width: 1.57rem;
        height: 1.163rem;
      }
    }
    &.card-selected {
      .card-title {
        height: 1.813rem;
        width: 3rem;
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .card-icon img {
        width: auto;
        height: 1.875rem;
        color: white;
      }
    }
  }

  @media (max-width: 390px) {
    .popup-card-title {
      width: unset;
    }
    &.popup-card-item {
      flex-direction: row;
      justify-content: left;
      flex: unset;
      width: 20.763rem;
      padding: 0 5.219rem;
      transition: transform 0.2s ease-in-out;
    }
  }
`;
