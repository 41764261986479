import React from "react";
import ErrorIcon from "../assets/images/network-error.svg";
import FooterButton from "../shared/components/footerButton/FooterButton";

const NetworkErrorPage = (props) => {
  const { closePage } = props;
  return (
    <div className="TopSection">
      <img src={ErrorIcon} alt="network-error" />
      <div className="Heading">Internal Server Error !</div>
      <div className="SubHeading">
        The server encountered an internal error and was unable to complete your
        request
      </div>
      <div className="BottomText">Please try again later.</div>
      <div className="close-btn-container">
        <FooterButton
          label="Close"
          btnClass="closeBtnError"
          btnClick={() => {
            closePage();
          }}
        />
      </div>
    </div>
  );
};

export default NetworkErrorPage;
