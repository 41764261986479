import React, { useState, useEffect } from 'react';
import './ProgressBarComponent.css';

function ProgressBarComponent(props) {
  const { index } = props;
  const [animationClass, setAnimationClass] = useState('');

  const fillerStyles = {
    height: '8px',
    width: `${Math.min(props?.value, 100)}%`,
    backgroundColor: props?.bgcolor,
    borderRadius: '20px 20px 20px 20px',
  };

  const containerStyles = index > 0 ? 'containerStylesfirst' : 'containerStyles';

  useEffect(() => {
   
    setAnimationClass('filler-anim');
  }, []);

  return (
    <div className={containerStyles}>
      <div className={animationClass} style={fillerStyles}></div>
    </div>
  );
}

export default ProgressBarComponent;
