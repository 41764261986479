import { Slide } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useStore,useDispatch } from "react-redux";
import FooterButton from "../footerButton/FooterButton";
import NumberQuestion from "../questionComponents/NumberQuestion";
import RatingQuestion from "../questionComponents/RatingQuestion";
import "./PopupComponent.css";


const TransitionDown = (props) => {
  return <Slide {...props} direction="down" />;
};

export default function PopupComponent(props) {
  const dispatch = useDispatch();
  const { question, handleClose, handleRevert } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const store = useStore();
  const oldResponse = useMemo(() => {
    const responses = store.getState().responses[question?.parent_id];
    if (responses != null) {
      const result = responses?.find(
        (res) => res?.option === question?.optionName
      );
      return result?.followupResponse;
    }
    return null;
  }, [store]);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [fadeout, setFadeout] = useState(false);

  useEffect(() => {
    if (!fadeout) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [fadeout]);

  const minSwipeDistance = 20;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isBottomSwipe = distance < -minSwipeDistance;
    if (isBottomSwipe) {
      closePopup();
    }
  };

  const closePopupForcefully=()=>{
    setFadeout(true);
    handleRevert(oldResponse, question?.optionName);
    dispatch({
      type: "UPDATE_ISVALID_RESPONSE",
      payload: {
        isValidRes:true
      },
    });

  }

  const closePopup = () => {
    if (store.getState().isValidRes) {
      setFadeout(true);
      handleRevert(oldResponse, question?.optionName);
    }
  };

  const [disableBtn, setDisableBtn] = React.useState(true);

  const handleToast = (Transition) => {
    setDisableBtn(true);
  };

  const closePopupOK = () => {
    setFadeout(store.getState().isValidRes);
    return handleClose(question?.optionName);
  };

  const renderQuestion = () => {
    if (question?.type === "rating" && !props?.confirmBox) {
      return (
        <RatingQuestion
          className="question-popup"
          optionName={question?.optionName}
          questionID={question?.parent_id}
          answerList={question?.options}
          popup={true}
          title={question?.label}
          titleAdd={question?.labelAdd}
          setDisableBtn={setDisableBtn}
        />
      );
    } else if (question?.type === "number" && !props?.confirmBox) {
      return (
        <NumberQuestion
          className="question-popup"
          optionName={question?.optionName}
          questionID={question?.parent_id}
          title={question?.label}
          titleAdd={question?.labelAdd}
          unit={question?.unit}
          inputTitle={question?.subLabel}
          placeholder={question?.placeholder}
          popup={true}
          modalVisible={modalVisible}
          type={question?.type}
          pattern={question?.pattern}
          inputMode={question?.inputMode}
          min={question?.min}
          max={question?.max}
          decimalLen={question?.decimalLen}
          cofirmBox={props?.cofirmBox} 
          handleClick={() => {
            setFadeout(store.getState().isValidRes);
            return handleClose(question?.optionName);
          }}
          onInputChange={(e) => {
            setDisableBtn(e.isBtnDisabled);
          }}
        />
      );
    }
  
    return null;
  };

  const renderButtons = () => {
    if (!props?.confirmBox) {
      if (question?.optionName === "Fever") {
        return renderFeverButtons();
      } else {
        return renderDefaultButtons();
      }
    }
  
    return null;
  };
  
  const renderFeverButtons = () => (
    <div className="button-popup-fever">
      <FooterButton
        isDisabled={disableBtn}
        label="OK"
        fever="fever"
        btnClass={props.confirmBox ? "closeBtn-none" : "closeBtn-Fever"}
        btnClick={() => handleButtonClick()}
      />
    </div>
  );
  
  const renderDefaultButtons = () => (
    <div className="button-popup">
      <FooterButton
        label="Cancel"
        btnClass={props.confirmBox ? "closeBtn-none" : "cancelBtn"}
        btnClick={() => handleCancelButtonClick()}
        btnWrapperClass="cancel-button"
      />
      <FooterButton
        isDisabled={disableBtn}
        label="Ok"
        btnClass={props.confirmBox ? "closeBtn-none" : "closeBtn"}
        btnClick={() => handleButtonClick()}
      />
    </div>
  );
  
  const handleCancelButtonClick = () => {
    handleRevert(oldResponse, question?.optionName);
    return store.getState().isValidRes
      ? closePopup()
      : handleToast(TransitionDown);
  };
  
  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return store.getState().isValidRes
      ? closePopupOK()
      : handleToast(TransitionDown);
  };
  

  return (
    <div
      id="myModal"
      className={`modal  ${props?.confirmBox && "confirm-container"} 
        ${!props?.confirmBox && fadeout && "fadeout"} ${
        props?.confirmBox && fadeout && "fadeoutConfirmBox"
      }`}
      style={{ display: "block" }}
    >
      <div onClick={() => closePopup()} className={`popup-outer`}>
        {" "}
      </div>
      <div
        id="modelContent"
        data-test="model-content"
        className={`modal-content ${
          props.confirmBox ? "popup-none" : "popup-container"
        }
         ${props.confirmBox && "confirm-item"} ${
          !props.confirmBox && fadeout && "fadeout"
        } ${props.confirmBox && fadeout && "fadeoutConfirmBox"}`}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {props?.confirmBox === false && (
          <div
            className="headline"
            onClick={() => {
              closePopupForcefully();
            }}
          ></div>
        )}
  
        {renderQuestion()}
  
        {renderButtons()}
  
        {props?.confirmBox && (
          <div>
            <div className="question-title confirm-title">
              Are you sure you want to select "None"?
            </div>
            <div className=" btnTitleNone confirm-subtitle">
              It will clear your previous selections on this page.
            </div>
          </div>
        )}
  
        {props?.confirmBox && (
          <div className="popup-btn-container">
            <FooterButton
              label="Cancel"
              btnClass="noneCloseBtn"
              btnClick={() => {
                return [handleClose({ selectNone: false }), setFadeout(true)];
              }}
              btnWrapperClass="cancel-button"
            />
            <FooterButton
              label="Yes"
              btnClick={() => {
                return [handleClose({ selectNone: true }), setFadeout(true)];
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
