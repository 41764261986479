import { configureStore } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  responses: {},
  isValidRes: true,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "BULK_UPDATE": {
      return { ...state, responses: payload.response };
    }

    case "ADD_RESPONSE": {
      const { payload } = action;

      // Create a copy of the responses
      let newResponses = JSON.parse(JSON.stringify(state.responses));

      if (payload.responseType === "checkbox") {
        // Create a new array for checkbox type if it doesn't exist
        if (!newResponses[payload.questionID]) {
          newResponses[payload.questionID] = [];
        }

        // Find the index if the response option already exists
        const existingIndex = newResponses[payload.questionID].findIndex(
          (res) => res.option === payload.response.option
        );

        if (existingIndex === -1) {
          // If not found, push a new response
          newResponses[payload.questionID].push(payload.response);
        } else {
          // If found, update the response
          newResponses[payload.questionID][existingIndex] = payload.response;
        }
      } else {
        // For non-checkbox type, update the response directly
        newResponses[payload.questionID] = payload.response;
      }

      // Update isValidRes if present in the payload
      const isValidRes = Object.prototype.hasOwnProperty.call(
        payload,
        "isValidRes"
      )
        ? payload.isValidRes
        : state.isValidRes;

      return {
        ...state,
        responses: newResponses,
        isValidRes,
      };
    }

    case "REMOVE_NONE": {
      const { payload } = action;

      // Create a new responses object to avoid mutating the original state
      let newResponses = { ...state.responses };

      if (
        payload.responseType === "checkbox" &&
        newResponses[payload.questionID] !== null
      ) {
        // Filter out the "None" option and keep other options
        newResponses[payload.questionID] = newResponses[
          payload.questionID
        ].filter((res) => res.option !== "None");
      }

      // Return a new state object with the updated responses
      return {
        ...state,
        responses: newResponses,
      };
    }

    case "REMOVE_RESPONSE": {
      const { payload } = action;

      // Create a new responses object to avoid mutating the original state
      let newResponses = { ...state.responses };

      if (payload.responseType === "checkbox") {
        if (newResponses[payload.questionID] !== null) {
          newResponses[payload.questionID] = newResponses[
            payload.questionID
          ].filter((res) => res.option !== payload.response.option);

          // If the array is empty, set it to null
          if (newResponses[payload.questionID].length === 0) {
            newResponses[payload.questionID] = null;
          }
        }
      } else if (payload.responseType === "number") {
        // Check if the key exists and remove it if it does
        if (newResponses[payload.questionID]) {
          delete newResponses[payload.questionID];
        }
      }

      // Return a new state object with the updated responses
      return {
        ...state,
        responses: newResponses,
      };
    }

    case "UPDATE_ISVALID_RESPONSE": {
      const { payload } = action;
      let newResponses = { ...state.responses };

      return {
        ...state,
        responses: newResponses,
        isValidRes: payload.isValidRes,
      };
    }

    case "CLEAR_RESPONSE": {
      let responses = { ...state.responses };
      if (payload.responseType === "checkbox") {
        if (responses[payload.questionID] != null) {
          responses[payload.questionID] = [
            { option: "None", followupResponse: "" },
          ];
        }
      }
      return { ...state, responses: responses };
    }
    default:
      return state;
  }
};

const store = configureStore({
  reducer,
});

export default store;
