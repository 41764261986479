import React from 'react';
import { Container } from 'react-bootstrap';

function HomeLayout(props) {
  return (
    <div style={{background: 'var(--backgroundColor)'}}>
    <Container
      style={
        {
          width: '100%',
          minHeight: '100%',
          padding: '0px',
          display: 'flex',
          flexDirection: 'column'
        }
      }>
      {props?.children}
      </Container>
      </div>
  )
}

export default HomeLayout;
