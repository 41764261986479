import React from 'react';
import CardComponent from '../cardComponent/CardComponent';
import QuestionTitle from './QuestionTitle';

function RatingQuestion(props) {
  const { question, handleClose, handleRevert } = props;
  const setDisableBtn = props.setDisableBtn;

  return (
    <div className={`main-question-container question-absolute-container ${props?.popup && 'question-container-popup'}`}>
    { props?.title && <QuestionTitle label={props?.title} labelAdd={props?.titleAdd}  popup={props.popup} /> }
    {props?.answerList && ( 
      <div className={`answer-section ${props?.popup && 'answer-section-popup'}`}>
       <CardComponent optionName={props?.optionName} cardListData={props?.answerList} questionID={props?.questionID}  popup={props.popup}  setDisableBtn={setDisableBtn}question={question}
             handleClose={handleClose}
             handleRevert={handleRevert}  ></CardComponent>
       </div>
       )}
      </div>
  ) ;
  }
    
export default RatingQuestion;